import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Todd Anduze - Small Business Development - Websuasion Podcast"
        ogDescription="Todd Anduze is the Area Director of the Small Business Development Center at the University of Georiga. The SBDC’s goal is to enhance the economic well-being of Georgians by providing a wide range of educational services for small business owners and aspiring entrepreneurs."
        image="/images/podcast/season-one/todd-anduze.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Todd Anduze</h1>
						<h3>Small Business Development Center</h3>
						<p>
							Todd Anduze is the Area Director of the Small Business Development Center at the University of Georiga. The SBDC’s goal is to enhance the economic well-being of Georgians by providing a wide range of educational services for small business owners and aspiring entrepreneurs. Todd utilizes his experiences in the military, retail/manufacturing environments, and accounting services to promote the UGA SBDC.						</p>
						<p>
							In this episode of Websuasion Conversation, we discuss the business consulting services the SBDC provides, like business plans, marketing plans, human resources, and accounting.		        </p>
						<p>
			        <QuickLinkButton
			          content={"Todd's Profile"}
			          url={"https://www.georgiasbdc.org/staff-members/todd-anduze/"}
			          icon={"linkify"}
			        />
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/todd-anduze.jpg" className="rounded-square" alt="Todd Anduze of the Small Business Development Center" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode010-ToddAnduze.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Todd tells us about his background and how it has influenced his definition of teamwork.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/yzzFyO8nSK8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Working in the Mining Industry</h2>
							<p>
								Todd tells us how he got started in the mining industry while simultaneously obtaining a master's degree in Accounting.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/7zJMtFa0Da8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Small Business Development Center</h2>
							<p>
								Todd talks to us about the services that the SBDC is able to provide for small businesses.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/_ltg99DGQjE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Identifying the Client's Needs</h2>
							<p>
								Todd talks about providing the client with the people they need to succeed.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/HICBD0ivXeE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Definition of a Small Business</h2>
							<p>
								Todd tells us the definition of a small business and the services that SBDC can provide to both small and large companies.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/YHRSlXI_Mlc'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Challenges to Growing a Business</h2>
							<p>
								Todd discusses the challenges of expanding a business and how to set goals that will help businesses reach the next level.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/6lIyRfeLCcM'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Working in The Business and Not on the Business</h2>
							<p>
								Todd explains how a business plan is a strategy for success.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/EV_Ar5g7Dl0'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Intentional Business Growth</h2>
							<p>
								Todd tells us how to make conscious decisions to grow your business in specific directions.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/BtE3HQNzYck'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Personal Brand Management</h2>
							<p>
								Todd discusses classes offered by the Small Business Development Center and bringing in experts to discuss various topics to meet community needs.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/oUBTYzxW834'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Getting to the Next Level with Your Business</h2>
							<p>
								Todd explains the process of getting involved with the Small Business Development Center.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/YKU8Icssl5s'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Different Ways of Consulting</h2>
							<p>
								Todd tells us how he can consult over the phone or in video conferences depending on a business's needs.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/YQ-F_AFsWf0'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>App Developer Checklist</h2>
							<p>
								You've been busy building your business, not learning all the ins and outs of application development. There's no reason you should know what it takes to develop a quality, maintainable application and bring it to market. But, before you contract with any firm (including ours), you should understand what is considered the standard. So, we've developed a handy two-page checklist. It will arm you the questions you need to ask before you move forward.
							</p>
						  <QuickLinkButton
						              content={"Get Your Copy"}
						              url={"/files/developer-checklist.pdf"}
						              icon={"file"}
						            />
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/app-developer-checklist.png" className="rounded-image" alt="App Developer Checklist" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
